import wowInit from "./module/wowInit";
import adjustViewport from "./module/adjustViewport";
import smoothScroll from "./module/smoothScroll";
import loadScroll from "./module/loadScroll";
import toggleGnav from "./module/toggleGnav";
import showGotop from "./module/showGotop";
import tab from "./module/tab";
import popup from "./module/popup";
import accordion from "./module/accordion";

import "../scss/style.scss";

wowInit();
adjustViewport();
smoothScroll();
loadScroll();
toggleGnav();
showGotop();
tab();
popup();
accordion();

// トップページ
if (document.body.id === 'top') {
    

// 下層ページ
} else if (document.body.id === 'xxxx') {
    

}


